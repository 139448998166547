import { get } from "lodash";
import typewriter from "../typewriter/segment";
import { getIdentifyInfo } from "@bigspring/core-components/src/lib/segment";

const REDUCE_EVENTS_COMPANY_EXTERNAL_IDS = ["birla-white-3738"];

export const typewriterTrack = <
  E extends keyof typeof typewriter,
  P extends Partial<Parameters<(typeof typewriter)[E]>[0]> &
    Record<string, unknown>
>(
  event: E,
  props?: P
) => {
  const traits = getIdentifyInfo();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const method: any = typewriter[event];
  const composedProps = { ...(props ?? {}), sessionId: traits.sessionId };
  if (
    !REDUCE_EVENTS_COMPANY_EXTERNAL_IDS.includes(
      get(traits, "prevIdentifyParams.companyExternalId", "")
    ) ||
    Math.random() <= 0.1
  ) {
    method(composedProps, { context: { traits: traits.prevIdentifyParams } });
  }
};
export enum DerivedFormType {
  SURVEY = "survey",
  GRADED = "graded_quiz",
  UNGRADED = "ungraded_quiz",
}

export enum SkillSequenceSource {
  SKILL_IMAGE = "skill_image",
  LINKOUT_BUTTON = "linkout_button",
  VIDEO_BUTTON = "watch_button",
  UNDERSTAND_BUTTON = "understand_button",
  PRACTICE_BUTTON = "practice_button",
  REP = "rep",
}

export enum ScrollType {
  CLICK = "CLICK",
  SWIPE = "SWIPE",
}

export enum ScrollDirection {
  NEXT = "Next",
  PREV = "Prev",
}

//page call outside of typewriter
export const ERROR_DISPLAYED = {
  name: "Error Displayed",
  properties: (p: { errorMessage: string; errorCode?: string }) => ({
    ...p,
  }),
};

export enum RepResponsePlayerAction {
  PLAYED = "play",
  PAUSED = "paused",
  SCRUBBED = "scrubbed",
}

export enum ResponseType {
  TEXT = "text",
  AUDIO = "audio",
  VIDEO = "video",
  IMAGE = "image",
  PDF = "pdf",
}
